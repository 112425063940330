@import '@/pages/ViewModePage/ViewModePage.scss';

.Chart {
  :global {
    .ant-progress {
      &-status-success {
        color: #11235f !important;
      }

      &-text {
        color: #11235f !important;
      }
    }
  }

  &.DarkMode {
    :global {
      .ant-progress {
        &-status-success {
          color: var(--WHITE) !important;
        }

        &-text {
          color: var(--WHITE) !important;
        }
      }
    }
  }

  .Title {
    @extend .TextInter;

    margin-bottom: calc(0.8 * var(--spacing));

    opacity: 0.7;

    .DarkMode & {
      opacity: 0.5;
    }
  }

  .Value {
    @extend .TextJakarta;
  }
}
