@import '@/styles/Function.scss';

.Component {
  background-image: url('/assets/icons/background.svg') !important;
  background-position: center top !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;

  * {
    font-family: 'Roboto' !important;
  }
}

.Hamburger {
  svg {
    width: calc(1.6 * var(--spacing));
    height: calc(1.6 * var(--spacing));

    * {
      fill: var(--WHITE);
    }
  }
}

.Dropdown {
  width: 100%;
  justify-content: space-between;
  padding: 0 calc(1.6 * var(--spacing));
}

.ViewSkyXBtn {
  width: 100%;
  min-height: 40px;

  :global {
    justify-content: space-between;

    [class^='ViewModeLabel_ViewSkyX'] {
      flex-direction: row-reverse;
      justify-content: space-between;

      width: 100%;

      span {
        margin-left: 0;
      }
    }
  }
}

.LogOut {
  width: calc(2.4 * var(--spacing));
  height: calc(2.4 * var(--spacing));
  // margin: 0 calc(0.8 * var(--spacing));
  transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);

  &Btn {
    justify-content: space-between;
    width: 100%;
    min-height: 40px;
    margin-bottom: 8px;
    font-size: calc(1.6 * var(--font-size));

    &:hover {
      * {
        stroke: var(--BLUE_5);
      }
    }
  }
}

.Sider {
  @include generate-border(1px, var(--GRAY_4), right);
}
