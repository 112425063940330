.Page {
  flex: 1;
  display: flex;
  align-items: center;
  width: 100%;
  max-height: 100vh;
  padding: calc(2.4 * var(--spacing));
  background-color: var(--WHITE);

  @media (max-width: 767.98px) {
    padding: 0;
  }

  .Content {
    display: flex;
    align-content: center;
    justify-content: center;
    width: 100%;
  }
}

.Logo {
  display: block;
  max-width: calc(16.2 * var(--spacing));
  width: 100%;
}

.Form {
  display: flex;
  flex-direction: column;
  height: 100%;
  margin-right: calc(2.4 * var(--spacing));
  padding: calc(5.6 * var(--spacing)) calc(5.6 * var(--spacing)) 0;

  @media (max-width: 1199.98px) {
    padding: 0 calc(1.6 * var(--spacing));
  }

  @media (max-width: 767.98px) {
    padding: calc(2.4 * var(--spacing));
    margin-right: 0;
  }

  > * {
    margin-bottom: calc(3.2 * var(--spacing));
  }

  form {
    @media (min-width: 768px) and (max-width: 991.98px) {
      margin-bottom: calc(9.6 * var(--spacing));
    }
  }

  &Title {
    margin: 0 0 4px;

    color: var(--BLACK);
    display: flex;
    font-size: calc(2.4 * var(--font-size));
    font-weight: 500;
    line-height: calc(32 / 24);
  }

  &Greet {
    color: var(--GRAY_1);
    font-size: calc(1.4 * var(--font-size));
    font-weight: 400;
    line-height: calc(22 / 14);
  }
}

.Footer {
  &Text {
    margin-bottom: 0;

    color: var(--NEUTRAL_7);
    font-size: calc(1.2 * var(--font-size));
    line-height: calc(20 / 12);
  }
}
