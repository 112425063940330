.Form {
  padding: calc(1.6 * var(--spacing)) 0;

  :global {
    .ant-input-search-button {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  &Label {
    max-width: 264px;
    margin-bottom: 0;
  }

  &Input {
    input {
      font-size: calc(1.4 * var(--font-size));

      &::placeholder {
        font-size: inherit;
        color: var(--GRAY_3);
      }
    }
  }
}

.BtnSearch {
  width: 32px;
  background-color: var(--WHITE);
  border-color: var(--NEUTRAL_5);

  &:hover {
    color: var(--BLUE_5);

    border-color: var(--BLUE_5);
  }
}
