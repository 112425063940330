@import '@/styles/Function.scss';

.Text {
  &ThreeDots {
    @include text-ellipsis(1);
  }

  &Inter {
    transition: 0.3s all ease;

    font-family: 'Inter', sans-serif !important;
    font-size: calc(16px * var(--scale-value));
    font-style: normal;
    font-weight: 500;
    line-height: calc(24 / 16);
    opacity: 0.7;
    white-space: nowrap;

    .DarkMode & {
      opacity: 0.5;
    }
  }

  &Jakarta {
    transition: 0.3s all ease;

    font-family: 'Plus Jakarta Sans', sans-serif !important;
    font-size: calc(32px * var(--scale-value));
    font-style: normal;
    font-weight: 600;
    line-height: calc(40 / 32);
    white-space: nowrap;

    sup {
      font-family: inherit;
    }
  }
}

.BackgroundBlur {
  padding: calc(24px * var(--scale-value));

  background: rgba(255, 255, 255, 0.7);
  backdrop-filter: blur(calc(40px * var(--scale-value)));
  border-radius: 12px;

  .DarkMode & {
    backdrop-filter: blur(calc(32px * var(--scale-value)));
    background: rgba(6, 16, 21, 0.4);
  }
}
