@import '@/pages/ViewModePage/ViewModePage.scss';

.Body {
  flex: 1;
  position: relative;

  &.Loading {
    > * {
      opacity: 0;
    }
  }

  :global {
    text[id*='axis-label'] {
      // font-size: var(--font-size);
      line-height: 1.6;
      font-family: 'Inter', sans-serif !important;
      font-weight: 500;
    }

    [id^='-grid-line-'] {
      stroke: transparent;

      // EX: Design required 5 lines
      &:nth-child(1),
      &:nth-child(2),
      &:nth-child(3),
      &:nth-child(4),
      &:nth-child(5) {
        stroke: #ffffff;
      }
    }
  }

  canvas {
    width: 100% !important;
    height: 100% !important;
  }
}

.Spin {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 1 !important;
}

.Component {
  flex: 1;
  display: flex;
  height: 100%;
}

.Chart {
  &Column {
    flex: 1;

    .Header {
      .Title {
        @extend .TextInter;

        opacity: 1 !important;

        .DarkMode & {
          color: #ffffff;
        }
      }

      .Time {
        @extend .TextInter;

        margin: calc(2px * var(--scale-value)) 0 calc(8px * var(--scale-value));

        font-size: calc(12px * var(--scale-value));
        line-height: 2;
        color: rgba(17, 35, 95);

        span {
          font-family: inherit;
        }

        .DarkMode & {
          color: #ffffff;
        }
      }
    }
  }

  &Wrapper {
    height: calc(174px * var(--scale-value));
    width: calc(640px * var(--scale-value));

    position: relative;

    & > div {
      height: 100% !important;
      width: 100% !important;

      position: absolute;
      left: 0;
      bottom: 0;
    }

    svg {
      overflow: visible;
    }
  }
}

.Wrapper {
  @extend .BackgroundBlur;

  display: flex;
  flex-direction: column;
  height: 100%;
  padding: calc(32px * var(--scale-value)) calc(24px * var(--scale-value));
}
