.ViewSkyX {
  display: flex;
  align-items: center;

  span {
    margin-left: var(--spacing);

    font-size: calc(1.6 * var(--font-size));
    font-weight: 500;
    line-height: calc(24 / 16);
  }

  svg {
    &,
    * {
      fill: currentColor;
    }
  }
}
