@import '@/styles/Function.scss';

.Page {
  &Back {
    svg {
      width: generate-spacing(2.4);
      height: generate-spacing(2.4);
    }
  }

  &Header {
    @include generate-margin(1.4, 0, 1);

    &.IsSkyX {
      margin-top: 0;
    }
  }

  &Title {
    @include generate-margin(0, 2.4, 0, 1.6);
    @include generate-font-size(2, 2.8);
    @include text-ellipsis();

    font-weight: 500;
  }

  .Form {
    :global {
      .ant-form-item {
        .ant-row {
          @media (max-width: 767.98px) {
            flex-direction: column;

            .ant-form-item-label {
              text-align: start;
            }
          }
        }

        &-explain {
          &:not(:empty) {
            margin-bottom: generate-spacing(2.4) !important;
          }
        }
      }
    }
  }
}

.Breadcrumb {
  :global {
    li {
      &:last-child {
        a,
        span {
          color: var(--GRAY_2);
        }
      }
    }
  }
}

.Btn {
  &ViewInfo {
    @include generate-padding(0, 1.6);
    @include generate-font-size(1.6, 2.4);

    font-weight: 500;

    // @media (max-width: 767.98px) {
    //   margin-left: auto;
    // }

    svg {
      margin-right: 10px;

      &,
      * {
        fill: currentColor;
      }
    }
  }

  &Save {
    min-width: generate-spacing(13);
  }

  &AddVideo {
    min-height: 1px;
    height: unset;

    svg {
      &,
      * {
        fill: currentColor;
      }
    }

    span {
      @include generate-margin(0, 0, 0, 1);
    }
  }

  &RemoveVideo {
    min-height: 1px;
    padding: 0;
    margin-left: generate-spacing(2.4);

    border: 0;

    &:hover {
      background-color: var(--TRANSPARENT) !important;

      svg {
        opacity: 0.7;
      }
    }

    svg {
      width: generate-spacing(2.2);
      height: generate-spacing(2.2);

      transition: 0.3s all ease;
    }

    @media (max-width: 767.98px) {
      position: absolute;
      right: 0;
      top: 0;
    }
  }
}

.Section {
  border-radius: generate-spacing(1.6);
  background: var(--WHITE);
  overflow: hidden;

  .Group {
    &:not(:last-child) {
      @include generate-border(1px, var(--GRAY_8), bottom);
    }

    .Title {
      @include generate-font-size(1.6, 2.4);

      font-weight: 500;

      &Wrapper {
        @include generate-padding(1.6, 2.4);
      }
    }

    .Content {
      @include generate-padding(2.4);

      &.Videos {
        .Label {
          margin-bottom: generate-spacing(2.4);

          :global {
            .ant-form-item {
              &-label {
                flex: 142;
              }

              &-control {
                flex: 344;
              }
            }
          }
        }
      }

      .Label {
        margin-bottom: generate-spacing(2.4);

        :global {
          .ant {
            &-form-item {
              &-label {
                flex: 188;
              }

              &-control {
                flex: 344;
              }
            }

            &-input-number-group-addon {
              min-width: generate-spacing(5.4);
            }
          }
        }

        &:last-child {
          margin-bottom: 0;

          :global {
            .ant-form-item-explain-error {
              &:not(:empty) {
                margin-bottom: 0 !important;
              }
            }
          }
        }

        &.Percent {
          :global {
            .ant-input-number-group-addon {
              min-width: generate-spacing(3.6);
            }
          }
        }

        &VideoWrapper {
          display: flex;
          position: relative;

          &:last-child {
            .Label {
              margin-bottom: 0;

              :global {
                .ant-form-item-explain-error {
                  margin-bottom: 0 !important;
                }
              }
            }
          }
        }
      }
    }
  }
}
