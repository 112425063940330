@import '@/styles/Function.scss';

.Component {
  @include generate-border(1px, var(--GRAY_4), bottom);

  display: flex;
  align-items: center;
  // justify-content: center;
  min-height: calc(8 * var(--spacing));
  padding: 0 16px !important;
  position: sticky;
  top: 0;
  z-index: 10;

  background-color: var(--TRANSPARENT) !important;
  transition: 0.3s all ease;

  &.Scrolling {
    background-color: rgba(#fff, 0.75) !important;

    &.DarkMode {
      background-color: rgba(#000, 0.75) !important;
    }
  }

  .Logo {
    display: block;
    height: calc(6.4 * var(--spacing));
    width: calc(8.8 * var(--spacing));
  }
}

.Title {
  margin-left: calc(1.2 * var(--spacing));
  margin-right: auto;

  color: var(--GRAY_2);
  font-size: calc(2 * var(--font-size));
  font-weight: 500;
  line-height: calc(28 / 20);
}

.LogOut {
  width: calc(2.4 * var(--spacing));
  height: calc(2.4 * var(--spacing));
  margin: 0 calc(0.8 * var(--spacing));

  cursor: pointer;

  &:hover {
    * {
      fill: var(--BLUE);
    }
  }
}

.Dropdown {
  padding: 0 calc(1.6 * var(--spacing));
}

.View {
  &SkyX {
    &Btn {
      padding: 0 calc(1.7 * var(--spacing));

      span {
        margin-left: generate-spacing(1);

        font-weight: 500;
      }
    }
  }

  &Mode {
    &Btn {
      min-width: unset !important;
    }
  }
}
