.Component {
  display: flex;
  align-items: center;
  min-height: 40px;
  padding: 0 calc(1.1 * var(--spacing));

  &:global {
    &.ant-btn {
      $this: '.ant-btn';

      &-background-ghost {
        color: var(--BLUE_7);
      }
    }
  }

  &.Small {
    min-height: unset;
  }

  .Icon {
    height: calc(2.4 * var(--spacing));
    width: calc(2.4 * var(--spacing));
    transition: all ease 0.3s;

    * {
      fill: currentColor;
    }
  }
}

.Activated {
  &:global {
    &.ant-btn {
      $this: '.ant-btn';

      &-default {
        border-color: var(--BLUE_5);
        outline: rgba(#1890ff, 0.2) solid 2px !important;
      }

      &-primary {
        &,
        &:hover {
          background-color: var(--BLUE_8);

          &#{$this}-background-ghost {
            background-color: var(--TRANSPARENT);
            // border-color: var(--BLUE_8);
            // outline: rgba(#1890ff, 0.2) solid 2px !important;

            color: var(--BLUE_8);
          }
        }
      }
    }
  }

  .Icon {
    transform: rotate(180deg);
  }
}

.Overlay {
  :global {
    .ant-dropdown-menu {
      padding: 4px 0;
      overflow: hidden;

      &-item {
        border-radius: 0 !important;

        color: var(--GRAY_2) !important;

        &:hover {
          background-color: var(--BLUE_1) !important;
        }
      }
    }
  }
}
