@import '@/styles/Function.scss';

.labelClass {
  :global {
    .ant-form-item {
      &-explain {
        &:not(:empty) {
          margin-bottom: generate-spacing(2.4) !important;
        }
      }

      &-label {
        label {
          color: var(--GRAY_2);
        }
      }
    }
  }
}

.inputClass {
  ::placeholder {
    // color: var(--GRAY_3);
  }
}
