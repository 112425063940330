@import '@/styles/Function.scss';

.Table {
  --table-min-height: 150px;

  :global {
    .ant-table {
      $this: '.ant-table';

      font-size: calc(1.4 * var(--font-size));

      &-thead {
        font-weight: 500 !important;

        #{$this}-cell {
          user-select: none;
          cursor: default;

          &::before {
            background-color: var(--GRAY_4) !important;
          }
        }
      }

      &-body {
        min-height: var(--table-min-height);

        color: var(--GRAY_2);
        font-weight: 400 !important;

        #{$this}-cell {
          padding: calc(0.7 * var(--spacing)) calc(1.6 * var(--spacing));
        }
      }
    }

    .ant-pagination {
      &-item {
        border: 1px solid var(--NEUTRAL_5);

        &-active {
          color: var(--BLUE_5) !important;
          border-color: var(--BLUE_5);
        }
      }
    }

    button.ant-pagination-item-link {
      border: 1px solid var(--NEUTRAL_5) !important;
    }

    .ant-spin-container {
      opacity: 1;

      &::after {
        opacity: 0;
      }
    }
  }

  &Empty {
    :global {
      .ant-table-body {
        display: flex;
        flex-direction: column;
        justify-content: center;

        height: 550px;

        > table {
          td {
            border: unset !important;
          }
        }
      }
    }
  }

  &Empty {
    :global {
      .ant-table-body {
        > table {
          @media (max-width: 1439.98px) {
            margin-top: 16px;
          }
        }
      }
    }
  }

  &Qualifies {
    :global {
      .ant-table-body {
        height: 486px;
      }
    }
  }

  .Col {
    &Avatar {
      margin-right: 8px;
    }

    &Information {
      @include text-ellipsis(1);
    }

    &Action {
      &View {
        padding: 0 calc(1.6 * var(--spacing));

        color: var(--BLUE_7);

        svg {
          width: calc(2.2 * var(--spacing));
          height: calc(2.2 * var(--spacing));

          &,
          * {
            fill: currentColor;
          }
        }

        span {
          @include generate-font-size(1.4, 2.2);

          margin-left: 8px;
          margin-right: 0;

          font-weight: 500;
        }
      }

      &Details {
        min-width: calc(9.22 * var(--spacing));
        padding: 0 5px;
        margin-left: 8px;
      }
    }

    &Name {
      @include text-ellipsis(1);
    }

    &Progress {
      width: calc(20 * var(--spacing));
      font-size: calc(1.4 * var(--font-size));
    }
  }

  .Empty {
    @include generate-font-size(1.4, 2.2);

    display: flex;
    flex-direction: column;
    justify-content: center;

    color: var(--GRAY_2);
  }

  .Loading {
    --loading-size: 60px;

    min-width: var(--loading-size);
    max-width: var(--loading-size);
    min-height: var(--loading-size);
    max-height: var(--loading-size);

    span {
      margin: 0 !important;

      position: unset !important;

      font-size: var(--loading-size);
      color: var(--GRAY_3);
    }

    &Wrapper {
      position: relative;

      > div {
        display: flex;
        flex-direction: column;
        align-items: center;

        width: 100%;
        height: 100%;

        position: absolute;
        top: -49px; // spin height + text height + spacing
        left: 50%;
        transform: translateX(-50%);
      }
    }

    &Text {
      @include generate-font-size(1.4, 2.2);

      margin-top: generate-spacing(1.6);

      color: var(--GRAY_2);

      white-space: nowrap;
    }

    &Spin {
      animation: spin 1s linear infinite;

      @keyframes spin {
        0% {
          transform: rotate(0deg);
        }
        100% {
          transform: rotate(360deg);
        }
      }
    }
  }

  div:has(> .LoadingWrapper) {
    max-height: unset;

    top: 55px; // Table header height

    background-color: rgba($color: #fff, $alpha: 0.7);
  }
}
