@import '@/styles/Function.scss';
@import '@/pages/ViewModePage/ViewModePage.scss';

.Component {
  height: 100%;

  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 10;

  cursor: none;
  user-select: none;

  &.Show {
    cursor: default;
  }

  // Reset button css
  button {
    background-color: transparent;
    border: unset;
    padding: unset;
  }

  svg {
    width: calc(24px * var(--scale-value));
    height: calc(24px * var(--scale-value));
  }
}

.Wrapper {
  display: flex;
  justify-content: flex-end;
  gap: calc(32px * var(--scale-value));

  width: 100%;
  padding: calc(24px * var(--scale-value));
  padding-bottom: calc(72px * var(--scale-value));

  background: linear-gradient(180deg, #000000 -16.25%, rgba(0, 0, 0, 0) 100%);

  transition: all 0.3s ease;
  opacity: 0;
  visibility: hidden;

  .Show & {
    opacity: 1;
    visibility: visible;
  }
}

.Left {
  display: flex;
  align-items: center;
  gap: calc(32px * var(--scale-value));

  .Theme {
    display: flex;
    align-items: center;

    .Text {
      @extend .TextInter;

      color: var(--WHITE);

      &.Dark {
        opacity: 0.5;
      }
    }
  }

  .Switch {
    width: calc(40px * var(--scale-value));
    height: calc(16px * var(--scale-value));
    margin: 0 calc(8px * var(--scale-value)) 0 calc(5px * var(--scale-value));

    position: relative;

    background: rgba(255, 255, 255, 0.5);
    border-radius: 8px;

    .Icon {
      display: flex;
      justify-content: center;
      align-items: center;

      width: calc(24px * var(--scale-value));
      height: calc(24px * var(--scale-value));

      position: absolute;
      top: 50%;
      right: 0;
      transform: translateY(-50%);

      background-color: var(--WHITE);
      border-radius: 50%;
      transition: all 0.3s ease;

      svg {
        width: calc(14px * var(--scale-value));
        height: calc(14px * var(--scale-value));
      }

      .DarkMode & {
        transform: translate(calc(-16px * var(--scale-value)), -50%);
      }
    }
  }
}

.Right {
  display: flex;
  gap: calc(32px * var(--scale-value));
}

.Language {
  &Overlay {
    min-width: calc(118px * var(--scale-value)) !important;
  }
}
