.Component {
  justify-content: space-between;
  min-width: calc(13 * var(--spacing));

  font-size: calc(1.6 * var(--font-size));

  &.Small {
    min-width: calc(11.8 * var(--spacing));

    font-size: calc(1.4 * var(--font-size));
  }
}
