@import '@/styles/Function.scss';

.Page {
  @include generate-padding(2.2, 2.4, 3.4);

  display: flex;
  flex-direction: column;
  height: 100%;

  &.PageMember {
    max-width: calc(100vw - (generate-spacing(11.8) * 2));
    margin: 0 auto;

    @media (max-width: 767.98px) {
      max-width: 100vw;
    }
  }

  &Title {
    margin-bottom: calc(2.2 * var(--spacing));

    color: var(--GRAY_2);
    font-size: calc(3 * var(--font-size));
    font-weight: 500;
    line-height: calc(40 / 30);
  }
}

.Content {
  padding: 0 calc(2.4 * var(--spacing));
  background-color: var(--WHITE);
  border-radius: calc(1.6 * var(--spacing));
  overflow: hidden;
}
