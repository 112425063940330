.Btn {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: calc(4 * var(--spacing));

  &:global {
    &.ant-btn {
      $this: '.ant-btn';

      &-primary {
        // EX: demo for global class at same block of modules
      }

      &-default {
        &#{$this}-background-ghost:not(:hover):not(:active):not(:focus) {
          border-color: var(--NEUTRAL_5);

          color: var(--GRAY_2);
          font-size: calc(1.4 * var(--font-size));
          font-weight: 500;
          line-height: calc(22 / 14);
        }
      }
    }
  }

  &Small {
    min-height: unset;

    &:global {
      &.ant-btn {
        $this: '.ant-btn';

        &-default {
          &#{$this}-background-ghost {
            &:active {
              border-color: var(--BLUE_7);
              color: var(--BLUE_7);
            }
          }
        }
      }
    }
  }
}
