@mixin generate-margin($top, $right: $top, $bottom: $top, $left: $right) {
  margin-top: calc($top * var(--spacing));
  margin-bottom: calc($bottom * var(--spacing));
  margin-left: calc($left * var(--spacing));
  margin-right: calc($right * var(--spacing));
}

@mixin generate-padding($top, $right: $top, $bottom: $top, $left: $right) {
  padding-top: calc($top * var(--spacing));
  padding-bottom: calc($bottom * var(--spacing));
  padding-left: calc($left * var(--spacing));
  padding-right: calc($right * var(--spacing));
}

@mixin generate-font-size($size, $line-height: $size) {
  font-size: calc($size * var(--font-size));
  line-height: calc($line-height / $size);
}

@function generate-spacing($number) {
  @return calc($number * var(--spacing));
}

@mixin text-ellipsis($number: 1) {
  display: -webkit-box;

  -webkit-box-orient: vertical;
  -webkit-line-clamp: $number;
  line-clamp: $number;
  overflow: hidden;
  text-overflow: ellipsis;
}

@mixin generate-border($width, $color, $position: all) {
  $shadows: ();

  @if $position == all {
    $shadows: append($shadows, inset 0 0 0 #{$width} #{$color});
  } @else {
    @each $dir in top, right, bottom, left {
      @if index($position, $dir) {
        $x: 0;
        $y: 0;
        @if $dir == top {
          $y: $width;
        } @else if $dir == right {
          $x: -$width;
        } @else if $dir == bottom {
          $y: -$width;
        } @else if $dir == left {
          $x: $width;
        }

        $shadows: append($shadows, inset #{$x} #{$y} 0 0 #{$color}, comma);
      }
    }
  }

  box-shadow: $shadows;
}
