.Menu {
  border: unset !important;

  :global {
    .ant-menu-item {
      display: flex;
      align-items: center;
      width: calc(100% - (1.6 * var(--spacing)));
      margin: calc(0.8 * var(--spacing));
      padding: 0 8px;

      &:active,
      &-selected {
        background-color: var(--WHITE) !important;
      }
    }
  }

  &Link {
    display: flex;
    align-items: center;
    text-decoration: none;

    span {
      margin-left: 2px;

      color: var(--GRAY_2);
      font-size: calc(1.4 * var(--font-size));
      line-height: calc(22 / 14);
    }
  }
}

.Reset {
  &Background {
    background-color: var(--TRANSPARENT);
  }
}
