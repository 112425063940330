@import '@/styles/Function.scss';

@import '@/pages/ViewModePage/ViewModePage.scss';

.Component {
  --padding-gutter: calc(8px * 2);

  --capacity-container-value: calc(336px + var(--padding-gutter));
  --chart-container-value: calc(688px + var(--padding-gutter));

  --capacity-container: calc(var(--capacity-container-value) * var(--scale-value));
  --chart-container: calc(var(--chart-container-value) * var(--scale-value));
  --map-container: var(--capacity-container);

  max-height: calc(810px * var(--scale-value));
  min-height: calc(810px * var(--scale-value));
  max-width: calc(1440px * var(--scale-value));
  min-width: calc(1440px * var(--scale-value));
  padding: calc(24px * var(--scale-value));

  &.ActiveTransform {
    transform: scale(var(--transform-scale-value));
  }

  background: var(--TRANSPARENT) !important;

  // overflow: hidden;

  // :global {
  //   .ant {
  //     &-row {
  //       margin: -8px;
  //     }

  //     &-col {
  //       padding: 8px;
  //     }
  //   }
  // }

  &.DarkMode {
    color: var(--WHITE);
  }

  &.Hidden {
    max-height: 100vh;
    opacity: 0;
  }

  &Background {
    width: 100vw;
    height: 100vh;

    display: flex;
    align-items: center;
    justify-content: center;

    background-image: url('/assets/images/view-mode/details-background-light.png');
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;

    &.DarkMode {
      background-image: url('/assets/images/view-mode/details-background-dark.png');
    }
  }
}

.Capacity {
  @extend .BackgroundBlur;

  display: flex;
  flex-direction: column;
  height: 100%;
  padding-bottom: calc(31px * var(--scale-value));
  position: relative;
  z-index: 1;

  &Container {
    max-width: var(--capacity-container);
    min-width: var(--capacity-container);
  }

  .Header {
    position: relative;
    z-index: 1;

    &Image {
      margin: calc(-24px * var(--scale-value)) calc(-24px * var(--scale-value)) 0;
    }

    &Title {
      margin-top: calc(-67px * var(--scale-value));
      position: relative;

      .Label {
        @extend .TextInter;
      }
    }

    &Data {
      @extend .TextJakarta;

      margin: calc(8px * var(--scale-value)) 0;
    }
  }

  .Sun {
    width: calc(85 / 336 * 100%);
    aspect-ratio: 1;
    position: absolute;
    top: calc(24 / 202 * 100%);
    right: calc(32 / 336 * 100%);
    // transform: translate(50%, -50%);
    z-index: 5;
    animation: SunRotate 10s infinite linear;

    &Blur {
      @extend .Sun;

      width: 100%;
      top: calc(-76 / 202 * 100%);
      right: calc(-70 / 336 * 100%);
      z-index: -100;

      opacity: 0.7;
      filter: blur(calc(40px * var(--scale-value)));

      animation: none;

      &Wrapper {
        width: calc(290 / 336 * 100%);
        aspect-ratio: 1;

        position: absolute;
        top: 0;
        right: 0;
        z-index: -100;
      }
    }
  }

  .Body {
    margin-top: calc(-12px * var(--scale-value));

    .Item {
      display: flex;
      align-items: flex-end;
      height: calc(98px * var(--scale-value));
      position: relative;

      &:first-child {
        z-index: 1;
      }

      &:last-child {
        z-index: -1;
      }

      // .Stroke {
      //   width: 44px;
      //   margin-bottom: 20px;
      // }

      .Icon {
        width: calc(95px * var(--scale-value));
        margin-right: calc(16px * var(--scale-value));
      }

      .Text {
        .Number {
          @extend .TextJakarta;

          font-size: calc(24px * var(--scale-value));
          line-height: 1.25;
        }

        .Label {
          @extend .TextInter;
        }
      }
    }
  }

  .Logo {
    max-width: calc(137px * var(--scale-value));
    width: 100%;

    .DarkMode & {
      opacity: 0.5;
    }

    &Text {
      @extend .TextInter;

      position: absolute;
      bottom: 6px;
      left: calc(78px * var(--scale-value));
    }
  }
}

.Chart {
  height: 100%;

  &Container {
    max-width: var(--chart-container);
    min-width: var(--chart-container);
  }

  &Wrapper {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    gap: calc(16px * var(--scale-value));
    height: 100%;
  }

  .Data {
    flex: 68;
    width: 100%;
    min-height: calc(136px * var(--scale-value));
    max-height: calc(136px * var(--scale-value));

    .Wrapper {
      @extend .BackgroundBlur;

      display: flex;
      flex-direction: row;
      gap: 0;
      height: 100%;
      padding: calc(32px * var(--scale-value)) calc(24px * var(--scale-value));
    }

    .Content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      flex: 1;
    }

    .Item {
      display: flex;
      align-items: center;
      gap: calc(12px * var(--scale-value));

      .Icon {
        min-width: calc(32px * var(--scale-value));
        max-width: calc(32px * var(--scale-value));
        height: calc(32px * var(--scale-value));
      }
    }

    .Divider {
      // flex: 1;
      padding: 0 calc(40px * var(--scale-value));

      &Line {
        width: calc(2px * var(--scale-value));
        height: 100%;
        margin: 0 auto;

        background-color: #11235f0d;

        .DarkMode & {
          background-color: var(--GRAY_7);
        }
      }
    }
  }

  &Ring {
    flex: 1;

    &s {
      flex: 149;
      width: 100%;
      display: flex;
      gap: calc(16px * var(--scale-value));
    }

    .Wrapper {
      @extend .BackgroundBlur;

      width: 100%;
      height: 100%;

      .Admin & {
        padding: calc(29px * var(--scale-value)) calc(48px * var(--scale-value));
      }

      .Content {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;

        position: relative;
      }
    }

    .Ring {
      * {
        transition-duration: 2s !important;
      }

      :global {
        .ant {
          &-progress {
            &-inner {
              display: flex;
              align-items: center;
              justify-content: center;

              width: 100% !important;
              height: 100% !important;
            }

            &-circle {
              flex: 1;
              max-height: 100%;
              max-width: 100%;
            }
          }
        }
      }
    }
  }

  .Ratio {
    width: 100%;
    height: 100%;
    padding-top: 0 !important;
  }

  &Liquid {
    flex: 257;

    .Wrapper {
      padding: calc(20px * var(--scale-value));
    }
  }

  &Multiple {
    flex: 415;

    .Wrapper {
      display: flex;
      align-items: center;
      padding: calc(20px * var(--scale-value)) 0;

      .Content {
        flex: 196;

        margin-right: calc(24px * var(--scale-value));
      }

      .Desc {
        flex: 197;
        display: flex;
        flex-direction: column;
        gap: calc(40px * var(--scale-value));

        p {
          margin: 0 0 calc(8px * var(--scale-value)) calc(56px * var(--scale-value));
          color: var(--WHITE);

          &:first-child {
            @extend .TextInter;

            position: relative;

            &::after {
              content: '';
              width: calc(12px * var(--scale-value));
              height: calc(12px * var(--scale-value));

              position: absolute;
              top: 50%;
              left: calc(-26px * var(--scale-value));
              transform: translateY(-50%);

              border-radius: 50%;

              background: linear-gradient(180deg, #42bbff 25.64%, #009af0 100%);
            }

            &.Yellow {
              &::after {
                background: linear-gradient(180deg, #f8b734 34.09%, #da9e23 100%);
              }
            }
          }

          &:last-child {
            @extend .TextJakarta;
          }
        }
      }

      .RingSmall {
        width: 75%;
        height: 75%;

        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }
}

.Map {
  height: 100%;
  min-height: 300px;

  &Container {
    max-width: var(--map-container);
    min-width: var(--map-container);
  }

  .Wrapper {
    @extend .BackgroundBlur;

    height: 100%;
    padding: 0;
    position: relative;
    border-radius: 12px;
    overflow: hidden;

    > div {
      height: 100%;
    }
  }
}

@keyframes SunRotate {
  0% {
    transform: rotateZ(0deg);
  }

  50% {
    transform: rotateZ(180deg);
  }

  100% {
    transform: rotateZ(360deg);
  }
}
