.Component {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  position: relative;

  > div {
    width: 100% !important;
    height: 100% !important;
  }
}
