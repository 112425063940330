@import '@/styles/Function.scss';

.Page {
  --max-width-container: 60;
  --padding-container: calc(18px * 2);

  @include generate-padding(2.4, 1.8, 3.9);

  color: var(--GRAY_2);

  &Container {
    margin: 0 auto;

    max-width: calc(generate-spacing(var(--max-width-container)) + var(--padding-container));

    @media (min-width: 1200px) {
      --max-width-container: 120;
    }
  }

  &Wrapper {
    background: var(--WHITE);
    border-radius: generate-spacing(1.6);
  }

  &Head {
    @include generate-padding(1.6, 2.4);
    @include generate-border(1px, var(--GRAY_8), bottom);

    .Title {
      @include generate-margin(0);
      @include generate-font-size(1.6, 2.4);

      font-weight: 500;
    }
  }

  &Body {
    @include generate-padding(2.4);
    @include generate-font-size(1.4, 2.2);

    .Title {
      flex: 182;
      max-width: generate-spacing(18.2);
    }

    .Value {
      flex: 400;

      @media (min-width: 1440px) {
        margin-right: generate-spacing(5.4);
      }
    }

    .SwitchShowSkyXInfo {
      margin-bottom: 0;

      :global {
        .ant-form-item-control-input {
          min-height: 1px;
        }
      }
    }
  }
}
