@import '@/styles/Function.scss';

.Page {
  @include generate-padding(0, 2.4);

  &Head {
    @include generate-padding(1.6, 0);

    .Title {
      @include generate-font-size(2, 2.8);

      margin: 0;

      color: var(--GRAY_2);
      font-weight: 500;
    }
  }

  &Body {
    background-color: var(--WHITE);
    border-radius: generate-spacing(1.6);

    .Title {
      @include generate-padding(1.6, 2.4);
      @include generate-border(1px, var(--GRAY_4), bottom);
      @include generate-font-size(1.6, 2.4);

      color: var(--GRAY_2);
      font-weight: 500;
    }

    .Content {
      .Field {
        &Icon {
          @include generate-margin(0, 2.4, 0, 0);

          min-width: generate-spacing(3.2);
          min-height: generate-spacing(3.2);
        }

        &EqualText {
          @include generate-font-size(3.8, 4.6);

          margin: 0 auto;
          padding: 0 4px;

          color: var(--GRAY_3);
          font-weight: 500;
          text-align: center;
        }

        &Data {
          max-width: generate-spacing(19.8);
          width: 100%;
        }
      }
    }

    .Slice {
      .Config {
        min-width: generate-spacing(12.9);

        :global {
          .ant-form-item {
            label {
              color: var(--GRAY_2);
            }
          }
        }
      }

      &Demo {
        width: 100%;

        @media (min-width: 768px) {
          max-width: generate-spacing(31.2);
        }

        :global {
          .ant-carousel {
            background: var(--NEUTRAL_3);

            border: 8px solid var(--GRAY_8);
            border-radius: generate-spacing(0.8);

            .slick-dots {
              li {
                button {
                  background-color: var(--NEUTRAL_6);
                }
              }
            }
          }
        }

        .Label {
          @include generate-padding(0, 0, 0.8);
          @include generate-font-size(1.4, 2.2);
        }
      }
    }
  }
}

.Btn {
  &Save {
    min-width: generate-spacing(13);
  }
}
