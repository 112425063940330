.App {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  position: relative;
  // min-width: 100vw;

  &,
  * {
    font-family: Roboto, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Helvetica Neue', Arial,
      'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
      'Noto Color Emoji';
  }

  &Loading {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(255, 255, 255);
    z-index: -1;

    opacity: 0;
    visibility: hidden;
    transition: 0.3s all ease;

    &.Activated {
      visibility: visible;
      opacity: 1;
      z-index: 99999999;
    }
  }
}
