@import '@/styles/Function.scss';

.Button {
  @include generate-padding(0.3, 1.6);
  @include generate-font-size(1.6, 2.4);

  font-weight: 500;
}

.Modal {
  max-width: generate-spacing(41.6);

  font-family: 'Roboto';

  :global {
    .ant-modal-content {
      @include generate-padding(3.2, 3.2, 2.4, 3.2);
    }
  }
}

.Content {
  flex: 1;

  p {
    color: var(--GRAY_2);

    &:first-child {
      @include generate-font-size(1.6, 2.4);

      margin-bottom: generate-spacing(0.8);

      font-weight: 500;
    }

    &:last-child {
      @include generate-font-size(1.4, 2.2);

      margin-bottom: generate-spacing(1.2);
    }
  }
}
