.Component {
  flex: 148;
  display: flex;
  // height: 100%;
  min-width: calc((var(--chart-container-value) - var(--padding-gutter)) * var(--scale-value));
  max-width: calc((var(--chart-container-value) - var(--padding-gutter)) * var(--scale-value));

  .Carousel {
    flex: 1;
    display: flex;
    width: 100%;
    height: 100%;
  }

  :global {
    .ant-carousel {
      width: 100%;
      height: 100%;
    }

    .slick-list {
      width: 100%;

      .slick-track {
        height: 100%;

        .slick-slide {
          > div {
            height: 100%;
          }
        }
      }
    }
  }
}
