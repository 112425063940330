@import '@/pages/ViewModePage/ViewModePage.scss';
@import '@/styles/Function.scss';

.MapContainer {
  position: relative;
  width: 100%;
  height: 100%;

  transform: scale(var(--map-scale));

  :global {
    a[href^="http://maps.google.com/maps"]
    {
      display: none !important;
    }

    a[href^="https://maps.google.com/maps"]
    {
      display: none !important;
    }

    .gmnoprint a,
    .gmnoprint span,
    .gm-style-cc {
      display: none;
    }

    .gmnoprint div {
      background: none !important;
    }

    .gm-style-cc {
      display: none;
    }
  }
}

.Spin {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 1 !important;
}

.Overlay {
  width: 100%;
  height: 100%;
  max-height: calc(120px * var(--scale-value));
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;

  background: linear-gradient(180deg, #f8f8f8 65.44%, rgba(242, 242, 240, 0) 103.75%);

  .DarkMode & {
    background: linear-gradient(180deg, #0f1d27 51.39%, rgba(15, 29, 39, 0) 75.25%);
  }

  .DarkMode.Client & {
    max-height: calc(220px * var(--scale-value));

    background: linear-gradient(180deg, #0F1D27 49.27%, rgba(15, 29, 39, 0) 100%);
    // background: linear-gradient(180deg, #0F1D27 63.59%, rgba(15, 29, 39, 0) 100%);
  }

  &Bottom {
    @extend .Overlay;

    display: none;
    top: unset;
    bottom: 0;

    .DarkMode.Client & {
      display: block;
      height: calc(157px * var(--scale-value));

      background: linear-gradient(180deg, #0F1D27 63.59%, rgba(15, 29, 39, 0) 100%);
      transform: matrix(1, 0, 0, -1, 0, 0);
    }
  }
}

.MapWrapper {
  --margin-top: calc(110px * var(--scale-value));

  width: 100%;
  height: calc(100% - var(--margin-top));
  margin-top: var(--margin-top);

  position: relative;

  ::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 2;
    background-color: transparent;
  }

  * {
    cursor: default;
    user-select: none;
    background-color: transparent !important;
  }

  > div {
    width: 100%;
    height: 100%;
    position: relative;
  }
}

.Heading {
  --left: calc(24px * var(--scale-value));

  width: calc(100% - calc(var(--left) * 2)); // EX: 24px left * 2

  position: absolute;
  top: calc(32px * var(--scale-value));
  left: var(--left);
  z-index: 2;

  .Title,
  .SubTitle,
  .Desc {
    display: block;

    word-break: break-word !important;
    white-space: pre-wrap !important;
  }

  .Title {
    @extend .TextInter;

    margin-bottom: 8px;

    .Client & {
      @extend .TextJakarta;

      font-size: calc(32px * var(--scale-value));
      line-height: calc(40.32px * var(--scale-value));
      opacity: 0.6;
    }
  }

  .SubTitle {
    @extend .TextJakarta;
  }

  .Desc {
    @extend .TextInter;

    font-size: calc(12px * var(--scale-value));
    line-height: calc(18px * var(--scale-value));
    opacity: 0.7;
  }
}

.Weather {
  position: absolute;
  bottom: calc(32px * var(--scale-value));;
  left: calc(24px * var(--scale-value));;
  z-index: 3;

  &Temp {
    @extend .TextJakarta;

    opacity: 0.6;

    &Wrapper {
      display: flex;
      align-items: center;
      gap: calc(8px * var(--scale-value));

      margin-bottom: calc(8px * var(--scale-value));
    }
  }

  &Icon {
    svg {
      width: calc(40px * var(--scale-value));
      height: calc(40px * var(--scale-value));
    }
  }

  &Description {
    @extend .TextInter;
  }
}
