@import '@/styles/Function.scss';

.Component {
  background-image: url('/assets/icons/background.svg');
  background-position: center top;
  background-repeat: no-repeat;
  background-size: cover;

  // :global {
  //   .ant-layout {
  //     &,
  //     &-content,
  //     &-sider {
  //       background-color: var(--TRANSPARENT);
  //     }
  //   }
  // }

  .Reset {
    &Background {
      background-color: var(--TRANSPARENT);
    }
  }

  .Sider {
    @include generate-border(1px, var(--GRAY_4), right);
  }
}
