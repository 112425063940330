.Galleries {
  height: 100%;

  * {
    cursor: default !important;
    height: 100%;
  }

  &Image {
    flex: 1;
    object-fit: cover;

    &Wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;

      width: 100%;
      height: 100%;
    }
  }
}
