@import url('https://fonts.googleapis.com/css2?family=Inter:wght@500&family=Plus+Jakarta+Sans:wght@600&display=swap');

html,
body {
  --spacing: 10px;
  --font-size: 10px;

  @media (max-width: 767.98px) {
    --spacing: 9px;
    --font-size: 9px;
  }

  @media (max-width: 575.98px) {
    --spacing: 8px;
  }
}

// CUSTOM GLOBAL STYLES
svg {
  height: auto;
}
