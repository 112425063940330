@import '@/styles/Function.scss';
@import '@/pages/ViewModePage/ViewModePage.scss';

.Page,
.Carousel {
  --spacing: 10px !important;
  --font-size: 10px !important;

  width: 100vw;
  height: 100vh;

  color: #11235f !important;

  &.DarkMode {
    color: #fff !important;
  }
}

.Page {
  opacity: 1;
  visibility: visible;

  @media (max-width: 1024px) and (hover: none) and (orientation: portrait) {
    opacity: 0;
    visibility: hidden;
  }
}

.Alert {
  @include generate-padding(0, 1.6);

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10;

  text-align: center;
  background-image: url('/assets/images/view-mode/details-background-dark.png');
  transition: all 0.3s ease;
  opacity: 0;
  visibility: hidden;

  @media (max-width: 1024px) and (hover: none) and (orientation: portrait) {
    opacity: 1;
    visibility: visible;
  }

  .RotateIcon {
    margin-bottom: 16px;
    animation: rotate 1.3s infinite;

    @keyframes rotate {
      0% {
        transform: rotate(0deg);
      }

      100% {
        transform: rotate(90deg);
      }
    }
  }

  span {
    @extend .TextInter;

    color: white;
    opacity: 0.5;
  }
}

.FinalLayer {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100vw;
  height: 100vh;
  padding: calc(24px * var(--scale-value));
  position: fixed;
  top: 0;
  left: 0;

  background: rgba(239, 239, 239, 0.7);
  // backdrop-filter: blur(calc(16px * var(--scale-value)));
  backdrop-filter: blur(16px);
  opacity: 0;
  visibility: hidden;
  transition: 0.3s all ease;

  color: #11235f;

  &.DarkMode {
    background: rgba(14, 37, 53, 0.8);
    color: var(--WHITE);
  }

  &.Activated {
    opacity: 1;
    visibility: visible;
  }

  .Content {
    width: 100%;
    max-width: calc(922px * var(--scale-value));
    min-width: calc(922px * var(--scale-value));

    margin: 0 0 calc(85px * var(--scale-value));

    &.ActiveTransform {
      transform: scale(var(--transform-scale-value));
    }

    // @media (max-width: 1199.98px) {
    //   @include generate-margin(0);
    // }

    .Title {
      @extend .TextJakarta;

      max-width: calc(922px * var(--scale-value));
      min-width: calc(922px * var(--scale-value));
      margin: calc(25px * var(--scale-value)) 0 calc(16px * var(--scale-value));

      white-space: pre-line;
      font-size: calc(64px * var(--scale-value));
      line-height: calc(81px / 64px);

      .DarkMode & {
        opacity: 0.5;
      }

      // @media (max-width: 1199.98px) {
      //   margin: calc(15px * var(--scale-value)) 0 calc(10px * var(--scale-value));

      //   font-size: calc(32px * var(--scale-value));
      //   line-height: calc((32px / 40px) * var(--scale-value));
      // }
    }

    .Contact {
      @extend .TextInter;

      margin: 0 0 calc(48px * var(--scale-value));

      opacity: 0.8;

      .DarkMode & {
        opacity: 0.5;
      }

      // @media (max-width: 1199.98px) {
      //   margin: 0 0 calc(24px * var(--scale-value));

      //   font-size: calc(12px * var(--scale-value));
      //   line-height: calc((12px / 18px) * var(--scale-value));
      // }

      &Group {
        flex-wrap: nowrap;

        > div {
          --padding: 8px * 2;

          display: flex;
          min-width: calc((297px + var(--padding)) * var(--scale-value));
          max-width: calc((297px + var(--padding)) * var(--scale-value));
        }
      }

      &Item {
        flex: 1;
        padding: calc(48px * var(--scale-value)) calc(12px * var(--scale-value));
        backdrop-filter: blur(32px);
        background: rgba(255, 255, 255, 0.6);
        border-radius: 12px;

        text-align: center;

        .DarkMode & {
          background: rgba(6, 16, 21, 0.4);
        }

        // @media (max-width: 1199.98px) {
        //   padding: calc(24px * var(--scale-value)) calc(12px * var(--scale-value));
        // }
      }

      &Icon {
        display: flex;
        justify-content: center;
        margin: 0 0 calc(16px * var(--scale-value));

        svg {
          width: calc(40px * var(--scale-value));
          height: calc(40px * var(--scale-value));

          // @media (max-width: 1199.98px) {
          //   width: calc(26.66px * var(--scale-value));
          //   height: calc(26.66px * var(--scale-value));
          // }
        }
      }

      &Title {
        @extend .TextJakarta;

        font-size: calc(24px * var(--scale-value));
        line-height: calc(30px / 24px);

        // @media (max-width: 1199.98px) {
        //   font-size: calc(16px * var(--scale-value));
        //   line-height: calc((16px / 20px) * var(--scale-value));
        // }
      }
    }
  }

  .Logo {
    max-width: calc(192px * var(--scale-value));
    width: 100%;

    // @media (max-width: 1199.98px) {
    //   max-width: calc(130px * var(--scale-value));
    // }
  }
}
